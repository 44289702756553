import React from 'react'
import './layout.css'
import '../Card/card.css'
import '../CardCase/cardCase.css'
import '../FirstSection/index.css'
import '../SecondSection/index.css'
import '../Button/button.css'
import '../Footer/footer.css'
import '../EmailInput/emailInput.css'
import '../Terms/tos.css'
import SEO from '../seo'

export default function Layout({ children }) {
  return (
    <div className="layout-container">
      <SEO title="NagNag" image="https://i.imgur.com/mhNLGTY.png" />
      {children}
    </div>
  )
}
